<template>
    <div class="select">
        <div class="table-container">
            <div class="button_container">
                <div style="display: inline-block">
                    学科：
                    <el-select size="small" v-model="subjectId" placeholder="请选择" class="select_container input"
                               @change="onSemester()">
                        <el-option
                            v-for="item in subjectList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div style="display: inline-block">
                    学期：
                    <el-select size="small" v-model="semesterId" placeholder="请选择" class="select_container input"
                               @change="onSemester()">
                        <el-option
                            v-for="item in this.$store.state.chinese.semester.list"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div style="display: inline-block">
                    教材：
                    <el-select size="small" v-model="bookId" placeholder="请选择" class="select_container input">
                        <el-option
                            v-for="item in this.$store.state.chinese.book.list"
                            :key="item.id"
                            :label="item.publishing"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div style="display: inline-block">
                    单元名称：
                    <el-input size="small" placeholder="请选择" class="school_input select_container input"
                              v-model="unitName" style="width: 150px"></el-input>
                </div>
                <div style="display: inline-block">
                    章节名称：
                    <el-input size="small" placeholder="请选择" class="school_input select_container input"
                              v-model="chapterName" style="width: 150px"></el-input>

                </div>
                <div style="display: inline-block">
                    字词名称：
                    <el-input size="small" placeholder="请选择" class="school_input select_container input"
                              v-model="wordName" style="width: 150px"></el-input>
                </div>
                <el-button size="small" type="primary" class="select_container" @click="onSearch">查询
                </el-button>
                <el-button size="small" type="primary" class="select_container" @click="onReset">重置
                </el-button>
                <el-button size="small" type="primary" class="select_container" @click="addWord" v-if="hasAddBook">添加字词
                </el-button>
            </div>
            <el-table v-if="subjectId===1" border :data="this.$store.state.chinese.word.list">
                <el-table-column type="index" :index="indexCompute" label="序号" align="center"
                                 width="60px"></el-table-column>
                <el-table-column prop="name" label="字词" align="center" min-width="80px"></el-table-column>
                <el-table-column prop="pinyin" label="拼音" align="center"></el-table-column>
                <el-table-column prop="sound" label="读音" align="center" min-width="280px">
                    <template slot-scope="scope">
                        <audio :src="getBookUrl(scope.row.sound)" alt="" v-if="scope.row.sound" controls style="width: 260px"></audio>
                    </template>
                </el-table-column>
                <el-table-column prop="picurl" label="笔顺" align="center" min-width="120px">
                    <template slot-scope="scope">
                        <img v-for="item in getPicUrlList(scope.row.picurl)" style="width: 70px; margin: 0 10px" :src="getBookUrl(item)" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="semesterName" label="所属学期" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="publishing" label="所属教材" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="chapterName" label="所属章节" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="unitName" label="所属单元" align="center" min-width="100px"></el-table-column>
                <el-table-column label="操作" align="center" width="150px">
                    <template slot-scope="scope">
                        <el-link type="primary" icon="el-icon-delete" @click="deleteWord(scope.row)" v-if="hasDeleteBook">删除</el-link>&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-s-operation" @click="updateWord(scope.row)" v-if="hasPutBook">修改
                        </el-link>&nbsp;&nbsp;
                    </template>
                </el-table-column>
            </el-table>
            <el-table v-if="subjectId===2" border :data="this.$store.state.chinese.word.list">
                <el-table-column type="index" :index="indexCompute" label="序号" align="center"
                                 width="60px"></el-table-column>
                <el-table-column prop="yingwen" label="单词" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="symbol1" label="美式音标" align="center"></el-table-column>
                <el-table-column prop="sound1" label="美式发音" align="center" width="280px">
                    <template slot-scope="scope">
                        <audio :src="getBookUrl(scope.row.sound1)" alt="" v-if="scope.row.sound1" controls style="width: 260px"></audio>
                    </template>
                </el-table-column>
                <el-table-column prop="symbol2" label="英式音标" align="center"></el-table-column>
                <el-table-column prop="sound2" label="英式发音" align="center" width="280px">
                    <template slot-scope="scope">
                        <audio :src="getBookUrl(scope.row.sound2)" alt="" v-if="scope.row.sound2" controls style="width: 260px"></audio>
                    </template>
                </el-table-column>
                <el-table-column prop="picurl" label="图片" align="center" min-width="120px">
                    <template slot-scope="scope">
                        <img v-for="item in getPicUrlList(scope.row.picurl)" style="width: 70px; margin: 0 10px" :src="getBookUrl(item)" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="semesterName" label="所属学期" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="publishing" label="所属教材" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="chapterName" label="所属章节" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="unitName" label="所属单元" align="center" min-width="100px"></el-table-column>
                <el-table-column label="操作" align="center" width="150px">
                    <template slot-scope="scope">
                        <el-link type="primary" icon="el-icon-delete" @click="deleteWord(scope.row)" v-if="hasDeleteBook">删除</el-link>&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-s-operation" @click="updateWord(scope.row)" v-if="hasPutBook">修改
                        </el-link>&nbsp;&nbsp;
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-container">
            <el-pagination class="pagination afterClearfix"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="pagination.page+1"
                           :page-sizes="[10, 20, 30, 50]"
                           :page-size="pagination.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="this.$store.state.chinese.word.total"
                           style="float: right">
            </el-pagination>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import {checkPermission, getData, setData} from "@/utils/cookies";
    import axios from '@/libs/axios'

    export default {
        name: 'List',
        data() {
            return {
                subjectList: [{id: 1, name: "语文"}, {id: 2, name: "英语"}], // 学科列表
                subjectId: 2, // 学科id
                semesterId: undefined, // 学期id
                bookId: undefined, // 教材id
                unitName: undefined, // 单元名称
                chapterName: undefined, // 章节名称
                wordName: undefined, // 字词名称
                pagination: {
                    page: 0,
                    size: 10,
                },
                dialogVisible: false,//添加弹框
                modifyDialogVisible: false,//修改弹框
                hasAddBook: checkPermission("addBook"),
                hasPutBook: checkPermission("putBook"),//修改教材权限
                hasDeleteBook: checkPermission("deleteBook"),//删除教材的权限
            }
        },
        mounted() {
            this.semesterId = getData('wordInfo') && getData('wordInfo').semesterId;
            this.bookId = getData('wordInfo') && getData('wordInfo').bookId;
            this.semesterList();
            this.getBookList();
            this.getBookInfo();
            this.getWordList();

        },
        methods: {
            // 获取学期列表
            semesterList() {
                this.$store.dispatch('chinese/semesterList')
            },

            // 获取教材列表
            getBookList() {
                if (this.semesterId) {
                    this.$store.dispatch("chinese/bookList", {'semesterId': this.semesterId, "subjectId": this.subjectId, "size": 999});
                }
            },

            // 获取教材信息
            getBookInfo() {
                if (this.bookId) {
                    this.$store.dispatch("/v1/web/book", {'semesterId': this.semesterId, "size": 999});
                }
            },

            // 获取字词列表
            getWordList() {
                this.$store.dispatch("chinese/wordList", {
                    'subjectId': this.subjectId,
                    'semesterId': this.semesterId,
                    'bookId': this.bookId,
                    'unitName': this.unitName,
                    'chapterName': this.chapterName,
                    'name': this.wordName,
                    'page': this.pagination.page,
                    'size': this.pagination.size
                });
            },

            // 查询
            onSearch() {
                this.pagination.page = 0;
                this.getWordList()
            },

            // 重置
            onReset() {
                this.semesterId = undefined;
                this.bookId = undefined;
                this.unitName = undefined;
                this.chapterName = undefined;
                this.wordName = undefined;
                this.getWordList();
            },

            // 添加字词
            addWord() {
                this.$router.push({path: '/chinese/word/add', query: {subjectId: `${this.subjectId}`}});
            },

            // 删除字词
            deleteWord(row) {
                this.$store.dispatch("chinese/deleteWord", {
                    'id': row.id,
                }).then((data) => {
                    this.getWordList();
                });
            },

            // 修改字词
            updateWord(row) {
                row.subjectId = this.subjectId;
                setData('wordInfo', row)
                this.$router.push('/chinese/word/update');
            },

            // 获取资源地址
            getBookUrl(url) {
                return url && axios.getBookUrl(url) || "";
            },

            // 列表序号
            indexCompute(index) {
                return index + 1 + this.pagination.page * this.pagination.size;
            },

            // 页大小改变
            handleSizeChange(size) {
                this.pagination.size = size;
                this.getWordList();
            },

            // 页码改变
            handleCurrentChange(page) {
                this.pagination.page = page - 1;
                this.getWordList();
            },

            // 学期改变
            onSemester() {
                this.getBookList();
                this.bookId = undefined;
            },

            getPicUrlList(picurl) {
                if (picurl) {
                    return JSON.parse(picurl);
                } else {
                    return [];
                }
            },

            getAudio(audio) {
                try {
                    let obj = JSON.parse(audio);
                    if (typeof obj == 'object' && obj && obj.length > 0) {
                        return obj[0];
                    } else {
                        return "";
                    }
                } catch (e) {
                    return "";
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .select {
        padding: 0 15px;
    }

    .button_container {
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        align-items: center;

        .select_container {
            margin-right: 30px;
            margin-bottom: 30px;
        }

        .input {
            width: 150px;
        }

        .school_input {
            display: inline-block;
            width: 300px;
        }

        .upload_container {
            display: inline-flex;
            width: 80px;
            height: 32px;
            position: relative;
            overflow: hidden;
            background-color: #409EFF;
            border-radius: 5px;
            color: #ffffff;
            line-height: 32px;
            justify-content: center;
            margin-right: 30px;
            font-size: 12px;
        }

        .upload_input {
            display: inline-block;
            position: absolute;
            top: 0;
            left: -500px;
            width: 580px;
            height: 32px;
            border: none;
            outline: none;
        }
    }


</style>
